<template>
  <td class="px-0">
    <v-layout column>
      <v-flex v-for="(object, index) in value" :key="index" :style="{backgroundColor: index % 2 ? '#F9F9F9' : ''}">
        <DefaultLabel class="my-1 px-6">{{ `${object[column]} ${extension}`}}</DefaultLabel>
      </v-flex>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined
    },
    item: {
      default: undefined
    },
    value: {},
    column: {},
    extension: {}
  },
  components: {
    DefaultLabel: () => import("../text/DefaultLabel")
  },
  computed: {
    defaultValue() {
      if (this.value) {
        return this.value;
      } else if (this.item == undefined || this.header == undefined) {
        return "";
      } else {
        return this.item[this.header.value];
      }
    }
  }
};
</script>
